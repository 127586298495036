<template>
  <div>
    <section
      v-if="layoutInternalCreated"
      id="about"
      class="section-content content-text"
    >
      <div class="container">
        <div class="row">
          <div class="col-lg-7">
            <h2>Aplicação de Insulfilm em Vitrines</h2>

            <p>
              Um problema muito comum em lojas e estabelecimentos comerciais tem
              a ver com os danos a objetos causados pela radiação solar, como as
              roupas, sapatos, caixas, etc.
            </p>

            <p>
              A radiação solar, sem dúvida, causa grande insatisfação aos
              empresários, pois quando um produto é danificado pelo sol, é quase
              impossível para os vendedores efetivarem a venda da mercadoria.
            </p>

            <p>
              Uma opção que muitas pessoas consideram, é usar o
              <strong> insulfilme transparente para vitrine </strong> com
              nanotecnologia. É uma maneira de manter seu produto seguro e
              manter sua loja com boa aparência.
            </p>

            <div @click="index = 0" class="page-photo-container">
              <Photo
                :src="photos[0].thumb"
                :title="photos[0].title"
                :description="photos[0].description"
                :idLocation="photos[0].idLocation"
                :openLightBox="false"
              />
            </div>

            <p>
              Quando o assunto é modernidade, elegância, praticidade e
              segurança, o insulfilme para vitrines é ideal para garantir a
              prevenção de acidentes, proteção dos móveis e objetos dos raios
              nocivos à saúde e garante maior redução de calor no ambiente,
              dando maior eficiência ao ar condicionado.
            </p>

            <p>
              Nem todo mundo sabe, mas existem insulfilmes para vitrine com nano
              partículas de cerâmica (nano híbridas) que podem ajudar nos
              problemas que muitas janelas apresentam, como a alta emissividade
              da luz solar que pode danificar os produtos expostos nelas.
            </p>

            <p>
              O efeito dos raios solares é um problema recorrente em prédios,
              vitrines de lojas ou outros estabelecimentos comerciais que expõem
              produtos ao público.
            </p>

            <p>
              Muitas lojas e estabelecimentos são afetados não apenas pelas
              altas temperaturas, mas também pelo sol em seus produtos
              diariamente, o que pode causar todo tipo de dano e até inutilizar
              itens, como roupas que podem perder a cor original devido ao calor
              e a radiação UV.
            </p>

            <p>
              O mesmo vale para outros produtos como calçados, artigos de couro,
              eletrônicos e até alimentos. Isso sem dúvida levará à
              insatisfação, pois os itens danificados podem causar danos
              consideráveis, ficar inutilizados ou ser vendidos a um preço
              inferior ao normal.
            </p>

            <p>
              Muitos varejistas adotaram o insulfilm para vitrines com
              nanotecnologia para proteção solar, protegendo suas mercadorias
              dos efeitos nocivos do sol. E é garantido que o insulfilm protetor
              solar tenha um efeito protetor imediatamente após a instalação dos
              equipamentos de medição térmica e UV.
            </p>

            <h3>Como funcona a película de Insulfilm para vitrines</h3>

            <p>
              O Insulfilm para vitrines, com efeito de
              <strong>bloqueio solar</strong>, é instalado em lojas, o que não
              só protege os produtos e móveis expostos, mas também ajuda a
              manter o ar condicionado da loja , economizando custos de energia.
            </p>

            <p>
              Os insulfilmes que a MF usa, comprovadamente bloqueiam até 99% dos
              raios ultravioleta (UV) e 90% dos raios infravermelhos, que são as
              principais causas de câncer e outros problemas de pele em todo o
              mundo.
            </p>

            <p>
              Usamos insulfilm importado com garantia de fábrica, e o teste de
              bloco é realizado imediatamente após a instalação para que os
              clientes possam comprovar que nosso filme funciona.
            </p>

            <p>
              Ao utilizar a
              <router-link :to="{ name: 'about' }">MF Film</router-link> como
              seu instalador de insulfilme, você tem garantia do serviço e
              instalação. Aplicamos a melhor película com serviço de qualidade.
              <router-link :to="{ name: 'quotation' }"
                >Faça uma cotação!</router-link
              >
            </p>

            <h3>Visibilidade do insulfilme para vitrines</h3>

            <p>
              Uma dúvida frequente entre os comerciantes que estão em busca de
              fazer a aplicação de insulfilme de
              <strong>proteção para vitrines</strong> em seu estabelecimento é a
              respeito da nitidez da imagem por trás do vidro.
            </p>

            <p>
              Podemos afirmar que o insulfilme de proteção para vitrines é
              <strong>totalmente transparente</strong>, ou seja, é possível ter
              uma visão totalmente nítida dos produtos que estão por trás do
              vidro.
            </p>

            <p>
              Outro fator importante a ser considerado é que o insulfilme de
              proteção para vitrines mantém o vidro com aparência de novo e bem
              conservado.
            </p>

            <p>
              A aplicação do insulfilm na vitrine não prejudica a visibilidade
              dos produtos expostos, ao contrário, há um conforto visual e
              transparência que facilita a visualização dos itens expostos e os
              detalhes em que são igualmente percebidos. Podemos dizer que esses
              produtos mantêm suas propriedades atrativas porque não são mais
              danificados pela ação do sol.
            </p>

            <Table class="mb-0-5">
              <tr>
                <td>Transmissão de Luz Visível</td>
                <td class="text-right" width="20%">70%</td>
              </tr>
              <tr>
                <td>Reflexão de Energia Solar</td>
                <td class="text-right" width="20%">9%</td>
              </tr>
              <tr>
                <td>Transmissão de Raios Ultravioletas</td>
                <td class="text-right" width="20%">>1%</td>
              </tr>
              <tr>
                <td>Energia Total Refletida</td>
                <td class="text-right" width="20%">51%</td>
              </tr>
            </Table>

            <p>Cor levemente esverdeada ou azulada</p>

            <h3>Aplicação de insulfilmes para vitrines</h3>

            <p>
              É possível aplicar insulfilmes para vitrines com materiais de
              qualidade e com garantia de fábrica, bloqueando os raios solares,
              protegendo meradorias e ajudando a manter a temperatura do
              ambiente.
            </p>

            <p>
              A <router-link :to="{ name: 'about' }">MF Film</router-link> tem
              mais de 18 anos de experiência com profissionais especializados na
              instalação de insulfilms e garante o serviço completo.
            </p>

            <p>
              <router-link :to="{ name: 'quotation' }"
                >Faça uma cotação</router-link
              >, fale com um especialista e veja como as películas podem ajudar
              na preservação de bens e economia com ar condicionado.
            </p>
          </div>
          <!-- col end -->

          <div class="col-lg-5 section-content-illustration">
            <QuotationCall position="float" />
          </div>
          <!-- col end -->
        </div>
        <!-- row end -->
      </div>
    </section>
  </div>
</template>

<script>
import LayoutInternal from "@/layouts/LayoutInternal.vue";
import { photoExtention } from "@/mixin/photo-extention.js";
import { layoutInternalCreated } from "@/mixin/layout-internal-created-mixin.js";

const QuotationCall = () =>
  import(
    /* webpackChunkName: "QuotationCall" */ "@/components/cotacao/quotation-call.vue"
  );
const Photo = () =>
  import(/* webpackChunkName: "Photo" */ "@/components/photo/photo.vue");
const Table = () =>
  import(/* webpackChunkName: "Table" */ "@/components/shared/table/table.vue");

export default {
  mixins: [photoExtention, layoutInternalCreated],

  components: {
    QuotationCall,
    Photo,
    Table,
  },

  data() {
    return {
      layoutLoad: false,
      index: null,
      photos: [
        {
          description:
            " Bloqueia os raios solares permitindo a visibilidade e conservação da mercadoria ",
          idLocation: 8,
          src: "/images/fotos/ribeira-alves/pelicula-transparente-vitrine-ribeira-alves-09",
          thumb: "/images/fotos/ribeira-alves/small/pelicula-transparente-vitrine-ribeira-alves-09",
          title: " Película para Vitrine ",
        },
      ],
    };
  },

  mounted: function () {
    //console.log(this.photos)
    // console.log('mounted')
  },

  created() {
    this.$emit(`update:layout`, LayoutInternal);
    this.$emit(`update:title`, "Insulfilm para Vitrines");
    this.$emit(
      `update:introduction`,
      "Instalamos insulfilm nas vitrines para proteção solar em lojas e estabelecimentos comerciais"
    );
    this.$emit(`update:bgHeader`, "black");
  },
};
</script>

<style lang="scss" scoped>
</style>